import { storeToken } from "common/Authentication/storeToken";

export const Auth0Service = new (class {
  private domain = process.env.REACT_APP_AUTH0_DOMAIN!;
  private clientId = process.env.REACT_APP_AUTH0_CLIENT_ID!;
  private audience = process.env.REACT_APP_AUTH0_AUDIENCE!;

  getAuthTokenInUrl = () => {
    const hash = window.location.hash;
    if (!hash || hash.length <= 1) return null;
    const params = new URLSearchParams(hash.substring(1));
    const accessToken = params.get("access_token");
    return accessToken;
  };

  handleAuthTokenInUrl = () => {
    const accessToken = this.getAuthTokenInUrl();
    if (accessToken) {
      storeToken(accessToken, true);
    }
    return { hasToken: !!accessToken };
  };

  getAuth0LoginUrl = (email: string) => {
    const params = new URLSearchParams({
      response_type: "token",
      client_id: this.clientId,
      redirect_uri: `${window.location.origin}/auth/callback`,
      scope: "openid profile email",
      audience: this.audience,
      login_hint: email,
      ui_locales: "de"
    });

    return `https://${this.domain}/authorize?${params.toString()}`;
  };

  getAuth0LogoutUrl = () => {
    const params = new URLSearchParams({
      client_id: this.clientId,
      returnTo: `${window.location.origin}/login`
    });

    return `https://${this.domain}/v2/logout?${params.toString()}`;
  };

  logoutAndChangePassword = () => {
    const params = new URLSearchParams({
      response_type: "token",
      client_id: this.clientId,
      redirect_uri: `${window.location.origin}/auth/callback`,
      scope: "openid profile email",
      audience: this.audience,
      screen_hint: "change_password",
      prompt: "login"
    });

    window.location.href = `https://${this.domain}/authorize?${params.toString()}`;
  };
})();

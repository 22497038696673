import React from "react";
import { FeatureFlags, UserRole } from "global-query-types";
import { RideTableHeader } from "../RideTableHeader/RideTableHeader";
import { RideTableRow } from "../RideTableRow/RideTableRow";
import RideTableCell, { RideTableCellVariant } from "../RideTableCell/RideTableCell";
import { getEndpoint } from "common/GraphqlClient/httpLink";
import { TFunction } from "i18next";
import { WithTranslation } from "react-i18next";
import { formatDate } from "common/formatters";
import { RideBadgeColorsEnum, RideBadgeStatus } from "uiLibrary/components/RideBadgeStatus";
import { getWepaBookings_getWepaBookings_bookings } from "lib/api/queries/graphql/getWepaBookings";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { getToken } from "common/Authentication/getToken";
import NoResultsPlaceholder, { LinkProp } from "../NoResultsPlaceholder/NoResultsPlaceholder";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { useSimpleSortBehaviour } from "../useSimpleSortBehaviour/useSimpleSortBehaviour";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { MyTaxAdvisorCompany } from "taxAdvisor/types/MyTaxAdvisorCompany";
import { RideHeaderColumn } from "lib/types/types";
import { FeatureFlagService } from "../../lib/services/FeatureFlagService/FeatureFlagService";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";

export interface WepaBookingsTableProps extends WithTranslation {
  bookings: getWepaBookings_getWepaBookings_bookings[];
  asUser?: UserRole;
  finalizeBooking?: (annualId: number) => Promise<void>;
  refetch?: () => Promise<void>;
  companyId: string;
  gmbh: GmbHDetails;
  myTaxAdvisorCompany?: MyTaxAdvisorCompany | null;
  sortCallback: (column: RideHeaderColumn) => void;
  isWhitelisted?: boolean;
}

interface CommonTableProps extends Pick<WepaBookingsTableProps, "finalizeBooking"> {
  t: TFunction;
  token: string;
  userRole?: UserRole;
  isWepaClient?: boolean;
}

interface TableProps
  extends CommonTableProps,
    Pick<WepaBookingsTableProps, "bookings" | "companyId" | "sortCallback"> {}

interface TableRow extends CommonTableProps {
  booking: getWepaBookings_getWepaBookings_bookings;
  companyId: string;
}

const FileNameCell = ({ booking }: Pick<TableRow, "booking">) => (
  <RideTableCell
    variant={RideTableCellVariant.text}
    classes="mobile-left"
    value={booking.fileName ?? ""}
  />
);

const DateRangeCell = ({ booking }: Pick<TableRow, "booking">) => (
  <RideTableCell
    variant={RideTableCellVariant.secondaryText}
    classes="mobile-right"
    value={`${formatDate(booking.dateFrom)} - ${formatDate(booking.dateTo)}`}
  />
);

const DownloadCell = ({
  booking,
  token,
  companyId
}: Pick<TableRow, "booking" | "token" | "companyId">) => {
  if (booking.dateCalculated === null) {
    return (
      <div data-testid={"download-cell"}>
        <RideTableCell variant={RideTableCellVariant.noAction} />
      </div>
    );
  }
  return (
    <RideTableCell
      variant={RideTableCellVariant.actionDownload}
      classes="mobile-right"
      link={`${getEndpoint()}/api/wepa/bookings/${
        booking.id
      }?apiToken=${token}&companyId=${companyId}`}
    />
  );
};

const NomineeBookingRow = ({ booking, token, companyId }: TableRow) => (
  <RideTableRow colCount={3}>
    <FileNameCell booking={booking} />
    <DateRangeCell booking={booking} />
    <DownloadCell booking={booking} token={token} companyId={companyId} />
  </RideTableRow>
);

const NomineeTable = ({ bookings, t, token, companyId, sortCallback }: TableProps) => {
  const columns: RideHeaderColumn[] = [
    {
      label: t("wepa-bookings:filename"),
      name: "fileName"
    },
    {
      label: t("wepa-bookings:period"),
      name: "period"
    },
    {
      label: t("wepa-bookings:download"),
      name: "download"
    }
  ];

  return (
    <>
      <RideTableHeader columns={columns} sortCallback={sortCallback} isColumnsDisplay={true} />
      {bookings.map((booking, index) => (
        <NomineeBookingRow
          key={index}
          booking={booking}
          token={token}
          t={t}
          companyId={companyId}
        />
      ))}
    </>
  );
};

const FileNameWithDownload = ({
  booking,
  token,
  companyId
}: Pick<TableRow, "booking" | "token" | "companyId">) => {
  if (booking.status === "in_progress") {
    return <NoDateCalculated />;
  }

  return (
    <RideTableCell
      variant={RideTableCellVariant.primaryLink}
      classes="mobile-left"
      value={booking.fileName ?? ""}
      link={`${getEndpoint()}/api/wepa/bookings/${
        booking.id
      }?apiToken=${token}&companyId=${companyId}`}
    />
  );
};

const DateCalculatedCellWithDownload = ({
  booking,
  token,
  companyId
}: Pick<TableRow, "booking" | "token" | "companyId">) => {
  return (
    <RideTableCell
      variant={RideTableCellVariant.primaryLink}
      classes="mobile-left"
      value={formatDate(booking.dateCalculated ?? "")}
      link={`${getEndpoint()}/api/wepa/bookings/${
        booking.id
      }?apiToken=${token}&companyId=${companyId}`}
    />
  );
};

const DateCalculatedCell = ({ booking }: Pick<TableRow, "booking">) => {
  return (
    <RideTableCell
      variant={RideTableCellVariant.secondaryText}
      classes="mobile-left"
      value={formatDate(booking.dateCalculated ?? "")}
    />
  );
};

const NoDateCalculated = () => (
  <RideTableCell variant={RideTableCellVariant.secondaryText} value="---" />
);

const BookingStatusBadge = ({
  booking,
  t,
  isWepaClient
}: Pick<TableRow, "booking" | "t" | "isWepaClient">) => {
  const infix = isWepaClient ? "wepa-service" : "admin-service";

  const preliminary = {
    color: RideBadgeColorsEnum.DANGER,
    label: t(`wepa-bookings:statusFlags.${infix}.preliminary`)
  };

  const badgeInfo: { color: RideBadgeColorsEnum; label: string } =
    {
      customer_final: {
        color: RideBadgeColorsEnum.INFO,
        label: t(`wepa-bookings:statusFlags.${infix}.customer_final`)
      },
      partner_final: {
        color: RideBadgeColorsEnum.SUCCESS,
        label: t(`wepa-bookings:statusFlags.${infix}.partner_final`)
      },
      in_progress: {
        color: RideBadgeColorsEnum.WARNING,
        label: t(`wepa-bookings:statusFlags.${infix}.in_progress`)
      }
    }[booking.status ?? ""] ?? preliminary;

  return <RideBadgeStatus color={badgeInfo.color} label={badgeInfo.label} />;
};

const BookingStatusCell = ({
  booking,
  t,
  isWepaClient
}: Pick<TableRow, "booking" | "t" | "isWepaClient">) => (
  <RideTableCell
    variant={RideTableCellVariant.badge}
    classes="mobile-left"
    badge={<BookingStatusBadge booking={booking} t={t} isWepaClient={isWepaClient} />}
  />
);

const TaxAdvisorActionsCell = ({
  booking,
  t,
  finalizeBooking
}: Pick<TableRow, "booking" | "t" | "finalizeBooking">) => {
  if (booking.status === "partner_final") {
    return <RideTableCell variant={RideTableCellVariant.actionDone} />;
  }

  if (booking.status === "customer_final") {
    return (
      <ConfirmationModal
        onConfirm={async () => {
          if (!booking.id) return;
          finalizeBooking?.(booking.id);
        }}
        title={t("wepa-bookings:finalizeBookingPrompt.title")}
        dialogBody={
          <div data-testid="confirm-finalization-modal-content">
            {t("wepa-bookings:finalizeBookingPrompt.info")}
          </div>
        }>
        {(setVisibility) => (
          <RideTableCell
            variant={RideTableCellVariant.actionButton}
            value={t("wepa-bookings:finalizeBooking")}
            action={() => setVisibility(true)}
          />
        )}
      </ConfirmationModal>
    );
  }

  return <RideTableCell variant={RideTableCellVariant.noAction} classes="mobile-right" />;
};

const ClientTable = ({ bookings, t, token, companyId, sortCallback }: TableProps) => {
  const dateCalculatedColumn = getDateCalculatedColumn(t, token, companyId, false);
  const typeColumn = getTypeColumn(t, true);
  const periodColumn = getPeriodColumn(t);
  const fileNameColumn = getFileNameColumn(t, token, companyId);
  const downloadColumn = getDownloadColumn(t, token, companyId);

  const columns: RideHeaderColumn[] = [
    fileNameColumn,
    typeColumn,
    dateCalculatedColumn,
    periodColumn,
    downloadColumn
  ];

  return (
    <>
      <RideTableHeader columns={columns} sortCallback={sortCallback} isColumnsDisplay={true} />
      {bookings.map((booking, index) => (
        <RideTableRow colCount={columns.length} key={index}>
          {columns.map((column, columnIndex) => (
            <React.Fragment key={columnIndex}>{column.component?.(booking)}</React.Fragment>
          ))}
        </RideTableRow>
      ))}
    </>
  );
};

function getDateCalculatedColumn(t: TFunction, token: string, companyId, withDownload = true) {
  return {
    label: t("wepa-bookings:dateCalculated"),
    name: "dateCalculated",
    component: (booking) =>
      withDownload ? (
        <DateCalculatedCellWithDownload booking={booking} token={token} companyId={companyId} />
      ) : (
        <DateCalculatedCell booking={booking} />
      )
  };
}

function getPeriodColumn(t: TFunction) {
  return {
    label: t("wepa-bookings:period"),
    name: "period",
    component: (booking) => <DateRangeCell booking={booking} />
  };
}

function getTypeColumn(t: TFunction, isWepaClient: boolean | undefined) {
  return {
    label: t("wepa-bookings:type"),
    name: "type",
    component: (booking) => (
      <BookingStatusCell booking={booking} t={t} isWepaClient={isWepaClient} />
    )
  };
}

function getFileNameColumn(t: TFunction, token: string, companyId) {
  return {
    label: t("wepa-bookings:filename"),
    name: "fileName",
    component: (booking) => (
      <FileNameWithDownload booking={booking} token={token} companyId={companyId} />
    )
  };
}

function getDownloadColumn(t: TFunction, token: string, companyId) {
  return {
    label: t("wepa-bookings:download"),
    name: "url",
    component: (booking) => <DownloadCell booking={booking} token={token} companyId={companyId} />
  };
}

const TaxAdvisorTable = ({
  bookings,
  t,
  token,
  finalizeBooking,
  companyId,
  userRole,
  sortCallback,
  isWepaClient
}: TableProps) => {
  const dateCalculatedColumn = getDateCalculatedColumn(t, token, companyId);
  const periodColumn = getPeriodColumn(t);
  const typeColumn = getTypeColumn(t, isWepaClient);
  const fileNameColumn = getFileNameColumn(t, token, companyId);
  const downloadColumn = getDownloadColumn(t, token, companyId);

  const actionsColumn = {
    label: t("generic:actions"),
    name: "action",
    component: (booking) => (
      <TaxAdvisorActionsCell booking={booking} t={t} finalizeBooking={finalizeBooking} />
    )
  };

  const columns: RideHeaderColumn[] =
    userRole === UserRole.ThirdPartyTaxAdvisor
      ? [fileNameColumn, periodColumn, typeColumn, downloadColumn]
      : [dateCalculatedColumn, periodColumn, typeColumn, downloadColumn, actionsColumn];

  return (
    <>
      <RideTableHeader columns={columns} sortCallback={sortCallback} isColumnsDisplay={true} />
      {bookings.map((booking, index) => (
        <RideTableRow colCount={columns.length} key={index}>
          {columns.map((column, columnIndex) => (
            <React.Fragment key={columnIndex}>{column.component?.(booking)}</React.Fragment>
          ))}
        </RideTableRow>
      ))}
    </>
  );
};

const determineMissingFields = ({
  gmbh,
  asUser,
  myTaxAdvisorCompany,
  t
}: Pick<WepaBookingsTableProps, "gmbh" | "asUser" | "myTaxAdvisorCompany" | "t">) => {
  const missingFields: LinkProp[] = [];

  if (!gmbh.thirdPartyTaxAdvisorClientNumber && asUser === UserRole.ThirdPartyTaxAdvisor) {
    missingFields.push({
      href: `/third-party-tax-advisor/clients/${gmbh.id}/third-party-tax-advisor-client-number`,
      label: t("wepa-bookings:please-set-client-number")
    });
  }

  if (!myTaxAdvisorCompany?.advisorNumber && asUser === UserRole.ThirdPartyTaxAdvisor) {
    missingFields.push({
      href: `/third-party-tax-advisor/my-company/advisor-number/${gmbh.id}`,
      label: t("wepa-bookings:please-set-advisor-number")
    });
  }

  if (!myTaxAdvisorCompany?.advisorNumber && asUser === UserRole.TaxAdvisor) {
    missingFields.push({
      href: `/tax-advisor/my-company/advisor-number/${gmbh.id}`,
      label: t("wepa-bookings:please-set-advisor-number")
    });
  }

  if (!gmbh.clientNumber && asUser === UserRole.TaxAdvisor) {
    missingFields.push({
      href: `/tax-advisor/client-entities/${gmbh.id}/client-number`,
      label: t("wepa-bookings:please-set-client-number")
    });
  }

  return missingFields;
};

const WepaBookingsTable = ({
  asUser = UserRole.Client,
  bookings,
  t,
  finalizeBooking,
  refetch,
  companyId,
  gmbh,
  myTaxAdvisorCompany,
  isWhitelisted = false
}: Omit<WepaBookingsTableProps, "sortCallback">) => {
  const { sortedData, sortCallback } = useSimpleSortBehaviour(bookings);

  const missingFieldLinks = determineMissingFields({ gmbh, asUser, myTaxAdvisorCompany, t });

  if (missingFieldLinks.length > 0) {
    return (
      <NoResultsPlaceholder
        text={t("wepa-bookings:additional-information-required")}
        links={missingFieldLinks}
      />
    );
  }

  if (bookings.length === 0) {
    return <NoResultsPlaceholder text={t("wepa-bookings:no-results")} />;
  }

  const token = getToken();

  const renderWepaBookingsDownloadForClient = FeatureFlagService.instance.isEnabled(
    FeatureFlags.WepaBookingsDownloadForClient
  );

  const alertBar = !isWhitelisted && (
    <RideAlertBar
      type={AlertType.WARNING}
      title={t("wepa-bookings:service-continuity.title")}
      message={
        <>
          {t("wepa-bookings:service-continuity.message.part1")}{" "}
          <a href={`mailto:${t("wepa-bookings:service-continuity.message.email")}`}>
            {t("wepa-bookings:service-continuity.message.email")}
          </a>
        </>
      }
    />
  );

  if (asUser === UserRole.Client) {
    return renderWepaBookingsDownloadForClient ? (
      <>
        {alertBar}
        <ClientTable
          token={token}
          bookings={sortedData}
          t={t}
          companyId={companyId}
          sortCallback={sortCallback}
        />
      </>
    ) : (
      <NoResultsPlaceholder text={t("wepa-bookings:no-results")} />
    );
  }

  if (asUser === UserRole.Nominee) {
    return (
      <>
        <NomineeTable
          bookings={sortedData}
          t={t}
          token={token}
          companyId={companyId}
          sortCallback={sortCallback}
        />
      </>
    );
  }

  if (asUser === UserRole.TaxAdvisor || asUser === UserRole.ThirdPartyTaxAdvisor) {
    return (
      <>
        {alertBar}
        <TaxAdvisorTable
          bookings={sortedData}
          t={t}
          token={token}
          userRole={asUser}
          finalizeBooking={async (annualId) => {
            await finalizeBooking?.(annualId);
            await refetch?.();
          }}
          companyId={companyId}
          sortCallback={sortCallback}
          isWepaClient={gmbh.isWepaClient}
        />
      </>
    );
  }

  return <></>;
};

export default withTranslationReady(["wepa-bookings"])(WepaBookingsTable);

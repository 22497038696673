import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Auth0Service } from "auth/services/auth0.service";

export const AuthCallbackScene = () => {
  const history = useHistory();

  useEffect(
    function () {
      const { hasToken } = Auth0Service.handleAuthTokenInUrl();
      if (hasToken) {
        history.push("/");
      }
    },
    [history]
  );

  return <></>;
};

import { TFunction } from "i18next";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { StorageConfigStructure } from "components/client/company/components/GetStorageConfig";
import { Container } from "react-bootstrap";
import { WithTranslation } from "react-i18next";
import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { UserService } from "lib/services/UserService/UserService";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags, OrderCompanyStatusEnum, OrderProductTypeEnum } from "global-query-types";
import AdminServiceSignUpSection from "../AdminServiceSignUpSection/AdminServiceSignUpSection";
import { SuccessAnimatedModal } from "../SuccessAnimatedModal/SuccessAnimatedModal";
import LeiApplication from "../LeiApplication/LeiApplication";
import { CompanyProcessStatesSectionContainer } from "../CompanyProcessStatesSection/CompanyProcessStatesSectionContainer";
import CompanyOverviewSection from "../../../components/client/company/sections/CompanyOverviewSection";
import DocumentSection from "../DocumentSection/DocumentSection";
import { GrundungServiceStatusEnum } from "lib/models/client/GmbH";
import ContinueCompanyFoundingOrderBanner from "client/components/ContinueCompanyFoundingOrderBanner/ContinueCompanyFoundingOrderBanner";
import ContinueAddExistingCompanyOrderBanner from "../ContinueAddExistingCompanyOrderBanner/ContinueAddExistingCompanyOrderBanner";

type MasterDataProps = {
  gmbh: GmbHDetails;
  storageConfig: StorageConfigStructure[];
};
const MasterData = ({ gmbh, storageConfig, t }: MasterDataProps & WithTranslation) => {
  const userService = UserService.instance?.currentUser;
  const isMyCurrentUserDirector = gmbh.directors.some(
    (director) => director.person.id === userService?.person?.id
  );

  const showAdminServiceToWepaClient = FeatureFlagService.instance.isEnabled(
    FeatureFlags.WepaServiceSwitchToAdminService
  );
  const showContinueGSBanner =
    gmbh.grundungServiceStatus === GrundungServiceStatusEnum.ORDER_PENDING;

  const showContinueAddExistingCompanyOrderBanner = gmbh.orders.some(
    (o) =>
      o.productType === OrderProductTypeEnum.AddExistingCompany &&
      o.status === OrderCompanyStatusEnum.InProgress
  );

  const showCompanyOverview = !showContinueAddExistingCompanyOrderBanner && !showContinueGSBanner;
  const onlyBroker = FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerOnlySignup);

  const showAdminServiceSignUp =
    !onlyBroker &&
    showCompanyOverview &&
    (showAdminServiceToWepaClient || !gmbh.isWepaClient) &&
    isMyCurrentUserDirector;

  return (
    <>
      {showContinueGSBanner && <ContinueCompanyFoundingOrderBanner gmbh={gmbh} />}
      {showContinueAddExistingCompanyOrderBanner && (
        <ContinueAddExistingCompanyOrderBanner gmbh={gmbh} />
      )}
      {showAdminServiceSignUp && <AdminServiceSignUpSection gmbh={gmbh} />}
      <LeiApplication lei={gmbh.lei} companyId={gmbh.id} orders={gmbh.orders} />
      {showCompanyOverview && <CompanyOverview t={t} gmbh={gmbh} storageConfig={storageConfig} />}
    </>
  );
};

const CompanyOverview = ({
  t,
  gmbh,
  storageConfig
}: {
  t: TFunction;
  gmbh: GmbHDetails;
  storageConfig: StorageConfigStructure[];
}) => {
  return (
    <div data-testid="master-data-tab-content">
      <SuccessAnimatedModal
        expectedQueryKey="order-completed"
        title={t("generic:order-completed.title")}
        message={t("generic:order-completed.company-foundation-message")}
        urlAfterClose={`/client/entities/${gmbh.id}/general-info`}
      />
      <CompanyProcessStatesSectionContainer gmbh={gmbh} />
      <CompanyOverviewSection gmbh={gmbh} />
      {(gmbh.hasAdminService || !gmbh.isWepaClient) &&
        gmbh.hasPrimaryDataStorage &&
        storageConfig.length > 0 && (
          <Container>
            <DocumentSection
              testid="company"
              gmbh={gmbh}
              nodeList={gmbh.primaryDataNodeList}
              storageConfig={storageConfig}
            />
          </Container>
        )}
    </div>
  );
};

export default withTranslationReady(["generic"])(MasterData);

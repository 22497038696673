import { CompanyById_companyById } from "../../../lib/api/queries/graphql/CompanyById";
import React from "react";
import LeiNumberIssueStatusSection, { LeiNumberIssuedSection } from "./LeiNumberSection.partials";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { ProductPlaceholderPage } from "../ProductPlaceholderPage/ProductPlaceholderPage";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";

export interface LeiNumberSectionProps extends WithTranslation {
  company: CompanyById_companyById;
}

export const LeiNumberSection = withTranslationReady(["lei-number"])(
  ({ t, company }: LeiNumberSectionProps) => {
    const gmbh = new GmbHDetails(company);

    if (
      FeatureFlagService.instance.isEnabled(FeatureFlags.WealthNavigation) &&
      !gmbh.hasLeiSection &&
      !gmbh.lei
    ) {
      return (
        <ProductPlaceholderPage
          descriptionTag={[]}
          infoTag="generic:product-unavailable-placeholder.lei.description"
        />
      );
    }

    switch (company.leiOrderSource) {
      case "LEI_ADMIN":
        return (
          <LeiNumberIssueStatusSection
            showStatusBadge
            leiPartnerLogoPath="/assets/lei-partners/lei-admin-cooperation-logo.svg"
            leiSupportContactEmail={t(`lei-number:email@.href.LEI_ADMIN`)}
            leiSupportContactEmailLabel={t(`lei-number:email@.LEI_ADMIN`)}
            company={company}
          />
        );
      case "LEI_ZENTRALE":
        return (
          <LeiNumberIssueStatusSection
            showStatusBadge
            leiPartnerLogoPath="/assets/lei-partners/lei-zentrale-cooperation-logo.svg"
            leiSupportContactEmail={t(`lei-number:email@.href.LEI_ZENTRALE`)}
            leiSupportContactEmailLabel={t(`lei-number:email@.LEI_ZENTRALE`)}
            company={company}
          />
        );
      case "LEI_KAUF":
        return (
          <LeiNumberIssueStatusSection
            showStatusBadge
            leiSupportContactEmail={t(`lei-number:email@.href.LEI_KAUF`)}
            leiSupportContactEmailLabel={t(`lei-number:email@.LEI_KAUF`)}
            company={company}
          />
        );
      default:
        return <LeiNumberIssuedSection company={company} />;
    }
  }
);

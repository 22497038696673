import gql from "graphql-tag";
import { useLazyQuery } from "react-apollo";
import { GetWepaCompanyQuery, GetWepaCompanyQueryVariables } from "./graphql/GetWepaCompanyQuery";

export const GET_WEPA_COMPANY_QUERY = gql`
  query GetWepaCompanyQuery($id: String!) {
    getWepaCompany(id: $id) {
      id
      wepaId
      isWhitelisted
      brokerAccesses {
        id
        valid
        expiredAt
        errors
        type
      }
      brokerAccounts {
        id
        errors
        type
        accountId
      }
      annuals {
        id
        errors
        dateRange
      }
    }
  }
`;

export const GetWepaCompany = (id: string) =>
  useLazyQuery<GetWepaCompanyQuery, GetWepaCompanyQueryVariables>(GET_WEPA_COMPANY_QUERY, {
    variables: { id }
  });

import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "global-query-types";
import { DisabledFeatureFlag } from "sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";
import { useCompanyNavigation } from "../hooks/useCompanyNavigation";
import { NavigationMenuItem } from "./NavigationMenuItem";
import { ReactComponent as AdminServiceIcon } from "uiLibrary/Icons/AdminServiceIcon.svg";
import { ReactComponent as BankIcon } from "uiLibrary/Icons/BankIcon.svg";
import { ReactComponent as BrokerIcon } from "uiLibrary/Icons/BrokerIcon.svg";
import { ReactComponent as CompanyIcon } from "uiLibrary/Icons/CompanyIcon.svg";
import { ReactComponent as DocumentIcon } from "uiLibrary/Icons/DocumentIcon.svg";
import { ReactComponent as HomeIcon } from "uiLibrary/Icons/HomeIcon.svg";
import { ReactComponent as HomePageIcon } from "uiLibrary/Icons/HomePageIcon.svg";
import { ReactComponent as LeiIcon } from "uiLibrary/Icons/LeiIcon.svg";
import { ReactComponent as LogoutIcon } from "uiLibrary/Icons/LogoutIcon.svg";
import { ReactComponent as PlaceholderIcon } from "uiLibrary/Icons/PlaceholderIcon.svg";
import { ReactComponent as UserProfileIcon } from "uiLibrary/Icons/UserProfileIcon.svg";
import { ReactComponent as WepaIcon } from "uiLibrary/Icons/WepaIcon.svg";
import { ReactComponent as FaqIcon } from "uiLibrary/Icons/FaqIcon.svg";

const useClientPageUrls = () => {
  const { getCompanyPath } = useCompanyNavigation();

  return {
    MY_GMBHS: "/client/entities",
    PENDING_TASKS: "/client/pending-tasks",
    INVESTMENTS: "/client/investments",
    KNOWLEDGE: "/client/knowledge",
    PROFILE: "/client/my-profile",
    BANKING: "/client/banking",
    LOW_TAX_BROKER: "/client/low-tax-broker",
    HOME: getCompanyPath({ companyPage: `home` }),
    GENERAL_INFO: getCompanyPath({ companyPage: `general-info` }),
    BROKER: getCompanyPath({ companyPage: `invest` }),
    ADMIN_SERVICE: getCompanyPath({ companyPage: `admin-service` }),
    SECURITIES_BOOKING: getCompanyPath({ companyPage: `securities-booking` }),
    LEI: getCompanyPath({ companyPage: `lei` }),
    REAL_ESTATE: getCompanyPath({ companyPage: `real-estate` }),
    COMPANY_BANKING: getCompanyPath({ companyPage: `banking` })
  };
};

interface Props {
  onClick?: () => void;
}

const NavigationMenuItems = ({ onClick, t }: Props & WithTranslation) => {
  const pageUrls = useClientPageUrls();

  return (
    <>
      <div className="flex-grow-1" onClick={() => onClick?.()}>
        <FeatureFlag name={FeatureFlags.EnableHomeTab}>
          <NavigationMenuItem
            path={pageUrls.HOME}
            MenuIcon={HomePageIcon}
            title={t("generic:home-tab")}
          />
        </FeatureFlag>
        <NavigationMenuItem
          path={pageUrls.GENERAL_INFO}
          MenuIcon={CompanyIcon}
          title={t("generic:master-data")}
        />
        <NavigationMenuItem
          path={pageUrls.BROKER}
          MenuIcon={BrokerIcon}
          title={t("broker-v2:invest")}
        />
        <NavigationMenuItem
          path={pageUrls.ADMIN_SERVICE}
          MenuIcon={AdminServiceIcon}
          title={t("generic:admin-service")}
        />
        <NavigationMenuItem
          path={pageUrls.SECURITIES_BOOKING}
          MenuIcon={WepaIcon}
          title={t("generic:wepa-service")}
        />
        <NavigationMenuItem
          path={pageUrls.LEI}
          MenuIcon={LeiIcon}
          title={t("generic:lei-number")}
        />
        <FeatureFlag name={FeatureFlags.RE52AddRealEstateSection}>
          <NavigationMenuItem
            path={pageUrls.REAL_ESTATE}
            MenuIcon={HomeIcon}
            title={t("generic:real-estate")}
          />
        </FeatureFlag>
        <FeatureFlag name={FeatureFlags.BankingPageInCompanyNavigation}>
          <NavigationMenuItem
            path={pageUrls.COMPANY_BANKING}
            MenuIcon={BankIcon}
            title={t("generic:banking")}
          />
        </FeatureFlag>
        <FeatureFlag key="pending-actions" name={FeatureFlags.PendingActions}>
          <NavigationMenuItem
            path={pageUrls.PENDING_TASKS}
            MenuIcon={PlaceholderIcon}
            title={t("client.pending-tasks")}
          />
        </FeatureFlag>
        <DisabledFeatureFlag key="banking" name={FeatureFlags.BankingPageInCompanyNavigation}>
          <FeatureFlag name={FeatureFlags.BankAccountsList}>
            <NavigationMenuItem
              path={pageUrls.BANKING}
              MenuIcon={PlaceholderIcon}
              title={t("client.banking")}
            />
          </FeatureFlag>
        </DisabledFeatureFlag>
        <FeatureFlag key="investments" name={FeatureFlags.ClientInvestments}>
          <NavigationMenuItem
            path={pageUrls.INVESTMENTS}
            MenuIcon={PlaceholderIcon}
            title={t("client.investments")}
          />
        </FeatureFlag>
        <NavigationMenuItem
          key="knowledge"
          path={pageUrls.KNOWLEDGE}
          MenuIcon={DocumentIcon}
          title={t("client.knowledge")}
        />
      </div>

      <NavigationMenuItem
        key="faq"
        url={"https://www.ride.capital/faq"}
        path=""
        MenuIcon={FaqIcon}
        title={t("generic:faqs")}
      />
      <NavigationMenuItem
        path={pageUrls.PROFILE}
        MenuIcon={UserProfileIcon}
        title={t("client.my-profile")}
      />
      <NavigationMenuItem path="/logout" MenuIcon={LogoutIcon} title={t("logout")} />
    </>
  );
};

export default withTranslation(["header", "client", "generic"])(NavigationMenuItems);

import { ErrorResponse, onError } from "apollo-link-error";
import { promiseToObservable } from "./graphqlHelpers";
import { ErrorCodes } from "../../global-query-types";
import logger from "../Logger";
import { GraphQLError, printError } from "graphql";
import { NextLink, Operation } from "apollo-link";
import { ServerError, ServerParseError } from "apollo-link-http-common";
import { refreshToken } from "../Authentication/refreshToken";
import { logout } from "../Authentication/logout";
import * as Sentry from "@sentry/react";

export const handleGraphqlErrors = (
  graphQLErrors: readonly GraphQLError[] | undefined,
  forward: NextLink,
  operation: Operation
) => {
  if (!graphQLErrors || !graphQLErrors.length) {
    return;
  }

  const isAuth0Unauthorized =
    graphQLErrors.filter((e) => e.message === "invalid algorithm").length > 0;
  if (isAuth0Unauthorized) {
    logout();
    window.location.href = `${window.location.origin}/login`;
    return;
  }

  // Try token refresh
  const unauthorizedError =
    graphQLErrors.filter((e) => e.message === ErrorCodes.LOGIN_FAILED_UNAUTHORIZED).length > 0;
  const shouldRefresh = unauthorizedError && operation.operationName !== "Login";
  if (shouldRefresh) {
    logger.debug("Unauthorized, retrying with refreshed token");
    return promiseToObservable(refreshToken()).flatMap(() => forward(operation));
  }

  const refreshError =
    graphQLErrors.filter(
      (e) => e.message === ErrorCodes.LOGIN_TOKEN_REFRESH_FAILED || e.message === "jwt malformed"
    ).length > 0;
  if (refreshError) {
    logout();
    return;
  }

  graphQLErrors.forEach((error: GraphQLError) => {
    const sources = (error.path ?? []).map((path, index) => {
      const location = (error.locations ?? [])[index];
      return `${path}:${location.line}:${location.column}`;
    });
    const errorForSentry =
      error.originalError ?? new Error(`${sources.join(";")}: ${printError(error)}`);
    Sentry.captureException(errorForSentry);
  });

  graphQLErrors.map(({ message, locations, path }) =>
    logger.errorMessage(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
    )
  );
};

export const handleNetworkError = (
  networkError: Error | ServerError | ServerParseError | undefined
) => {
  if (networkError) {
    logger.errorMessage(`[Network error]: '${networkError.name}' '${networkError.message}'`);
  }
};

// based on https://www.apollographql.com/docs/react/data/error-handling/
export const errorLink = onError(
  ({ networkError, graphQLErrors, forward, operation }: ErrorResponse) => {
    handleNetworkError(networkError);
    return handleGraphqlErrors(graphQLErrors, forward, operation);
  }
);

import React from "react";
import { WithTranslation } from "react-i18next";
import { Panel } from "sharedComponents/Panel/Panel";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import RideInput, { InputState } from "../../../sharedComponents/FormControls/RideInput/RideInput";
import { FormikProvider, useFormik } from "formik";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import { emailSchemaWithTranslation } from "lib/validation/LoginSchema";
import RequestFeedbackForm from "../../../sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";

export interface LoginEmailInputFormProps {
  onSubmit: (email: string) => Promise<void>;
  isCheckingStrategy?: boolean;
}

const LoginEmailInputForm = ({
  onSubmit,
  isCheckingStrategy,
  t
}: LoginEmailInputFormProps & WithTranslation) => {
  const submitHandler = async (values) => await onSubmit(values.email);

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    onSubmit: submitHandler,
    validateOnBlur: true,
    validationSchema: emailSchemaWithTranslation(t)
  });

  return (
    <div className="login-form">
      <Panel>
        <div className="login-form__panel-content">
          <FormikProvider value={formik}>
            <RequestFeedbackForm submitLabel={t("auth:login.continue")} requests={[]}>
              <RideInput
                formik={formik}
                name="email"
                state={InputState.DEFAULT}
                isDisabled={isCheckingStrategy}
                type="text"
                label={t("auth:generic.email-address")}
              />
            </RequestFeedbackForm>
          </FormikProvider>
        </div>
      </Panel>

      <div className="login-form__footer">
        <TextLink
          variant={LinkVariant.secondary}
          className="login-form__link"
          to="/order/account-creation/0"
          data-testid="register-link">
          {t("auth:login.register")}
        </TextLink>
      </div>
    </div>
  );
};

export default withTranslationReady(["auth"])(LoginEmailInputForm);

import React, { PropsWithChildren } from "react";
import GuestHeader from "guest/components/GuestHeader/GuestHeader";
import { StepProgressBar } from "sharedComponents/StepProgressBar/StepProgressBar";
import BookACallCard from "client/components/ShortOrderFormEngine/ShortOrderFormLayout/BookACallCard/BookACallCard";
import Partners from "client/components/ShortOrderFormEngine/ShortOrderFormLayout/Partners/Partners";
import {
  AlertType,
  RideAlertBar
} from "../../../../uiLibrary/components/RideAlertBar/RideAlertBar";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";
import RideFooter from "sharedComponents/RideFooter/RideFooter";

interface ShortOrderFormLayoutProps extends PropsWithChildren<{}> {
  steps: string[];
  currentStepIndex: number;
}

const ShortOrderFormLayout = ({ steps, currentStepIndex, children }: ShortOrderFormLayoutProps) => {
  const onlyBroker = FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerOnlySignup);
  return (
    <div data-testid="short-order-form-layout" className="short-order-form-layout">
      <GuestHeader />
      <Sidebar>
        <StepProgressBar steps={steps} currentStepIndex={currentStepIndex} />
        <div className="my-3"></div>
        <BookACallCard />
        <Partners />
      </Sidebar>
      {onlyBroker ? (
        <Content>{children}</Content>
      ) : (
        <RideAlertBar
          className={"short-order-form-layout__info-bar"}
          type={AlertType.INFO}
          message={
            "Wir haben im Moment ein technisches Problem. Bitte versuchen Sie es später noch einmal"
          }
        />
      )}
    </div>
  );
};

const Sidebar = ({ children }: PropsWithChildren<{}>) => (
  <div className="short-order-form-layout__sidebar">{children}</div>
);

const Content = ({ children }: PropsWithChildren<{}>) => (
  <div className={"short-order-form-layout__content"}>
    <div className={"short-order-form-layout__step"}>{children}</div>
    <RideFooter />
  </div>
);

export default ShortOrderFormLayout;

import React, { useEffect, useState } from "react";
import { RideButtonDeprecated } from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { AddIcon } from "../../../sharedComponents/icons/add";
import BankAccountsList from "../BankAccountsList/BankAccountsList";
import { GmbHDetails } from "../../../lib/models/client/GmbHDetails";
import { StorageConfigStructure } from "../../../components/client/company/components/GetStorageConfig";
import { ExecutionResult } from "graphql";
import { CreateAdminServiceAddOn } from "../../../components/client/company/graphql/CreateAdminServiceAddOn";
import { useHistory } from "react-router-dom";
import { AdminServiceStatusEnum } from "../../../lib/models/client/GmbH";
import { FeatureFlag } from "../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { BankType, FeatureFlags, UserRole } from "../../../global-query-types";
import { PurchaseAdminService } from "../../../components/client/company/components/AdminServiceModal";
import AdminServiceSignUpSection from "../AdminServiceSignUpSection/AdminServiceSignUpSection";
import AdminServiceSection from "../AdminServiceSection/AdminServiceSection";
import { Container } from "react-bootstrap";
import DocumentSection from "client/components/DocumentSection/DocumentSection";
import { CompanyById_companyById } from "../../../lib/api/queries/graphql/CompanyById";
import { TFunction, Trans } from "react-i18next";
import { BankAccount } from "../../../lib/types/types";
import { getBankAccountById_getBankAccountById } from "../../../lib/api/queries/graphql/getBankAccountById";
import EditBankAccountModal from "../Banking/EditBankAccountModal/EditBankAccountModal";
import AddBankAccountModal from "../Banking/AddBankAccountModal/AddBankAccountModal";
import ContinueOrderBanner from "../ContinueOrderBanner/ContinueOrderBanner";
import AdminServiceTiersOnboardingContainer from "../AdminServiceTiersOnboarding/AdminServiceTiersOnboardingContainer";
import { FeatureFlagService } from "../../../lib/services/FeatureFlagService/FeatureFlagService";
import UpgradeAdminServiceToSelectTierBanner from "../MigrateAdminServicePackage/UpgradeAdminServiceToSelectTierBanner/UpgradeAdminServiceToSelectTierBanner";
import moment from "moment/moment";
import { FiscalYearDataUploadConfirmationContainer } from "../FiscalYearDataUploadConfirmation/FiscalYearDataUploadConfirmationContainer";
import { GetFormerClientAssignments_getFormerClientAssignments_items } from "../../../lib/api/queries/graphql/GetFormerClientAssignments";
import { LeiNumberSection } from "../LeiNumberSection/LeiNumberSection";
import { useQueryParams } from "lib/hooks/useQueryParams";
import ThankYouPage from "client/components/AdminServiceTiersOnboarding/ThankYouPage/ThankYouPage";
import { AlertType, RideAlertBar } from "../../../uiLibrary/components/RideAlertBar/RideAlertBar";
import { getBlockedTiersForAdminService } from "../../../common/getBlockedTiersForAdminService";
import { ProductPlaceholderPage } from "../ProductPlaceholderPage/ProductPlaceholderPage";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";

interface LeiTabSwitcherProps {
  company: CompanyById_companyById;
}

export const LeiTabSwitcher = ({ company }: LeiTabSwitcherProps) => {
  return (
    <FeatureFlag name={FeatureFlags.GRO1070AddLEINumberSectionForLEIAdmin}>
      <LeiNumberSection company={company} />
    </FeatureFlag>
  );
};

interface BankingTabSwitcherProps {
  gmbh: GmbHDetails;
  t: TFunction;
  company: CompanyById_companyById;
  refetchCompany: () => Promise<void>;
  upsertBankAccount: (vars: {
    bankProvider: BankType;
    companyId: string;
    iban?: string;
    name?: string;
  }) => Promise<void>;
}

export const BankingTabSwitcher = ({
  t,
  gmbh,
  company,
  upsertBankAccount,
  refetchCompany
}: BankingTabSwitcherProps) => {
  const [showEditBankAccountModal, setShowEditBankAccountModal] = useState(false);
  const [showAddBankAccountModal, setShowAddBankAccountModal] = useState(false);
  const [bankAccountToEdit, setBankAccountToEdit] =
    useState<getBankAccountById_getBankAccountById>();

  let bankAccountsLength = gmbh.bankAccounts?.length;
  useEffect(() => {}, [bankAccountsLength]);

  const onEditBankAccount = (bankAccount: BankAccount) => {
    setBankAccountToEdit(bankAccount as getBankAccountById_getBankAccountById);
    setShowEditBankAccountModal(true);
  };

  return (
    <div className="bank-accounts-list container" data-testid="bank-account-list-view">
      {showEditBankAccountModal && (
        <EditBankAccountModal
          id={bankAccountToEdit?.id ?? ""}
          bankProvider={bankAccountToEdit?.bankProvider ?? BankType.Own}
          accountName={bankAccountToEdit?.name ?? ""}
          iban={bankAccountToEdit?.iban ?? ""}
          onClose={async (doRefetch) => {
            setShowEditBankAccountModal(false);
            if (doRefetch) {
              await refetchCompany();
            }
          }}
          onSave={async (vars) => await upsertBankAccount({ ...vars, companyId: company.id })}
        />
      )}
      {showAddBankAccountModal && (
        <AddBankAccountModal
          upsertHandler={async (vars) => {
            await upsertBankAccount({ ...vars, companyId: company.id });
            await refetchCompany();
          }}
          onClose={() => {
            setShowAddBankAccountModal(false);
          }}
        />
      )}
      <RideButtonDeprecated
        className="bank-accounts-list__add-account-btn"
        data-testid="add-bank-account-btn"
        hasIcon={true}
        onClick={() => setShowAddBankAccountModal(true)}>
        <AddIcon />
        {t("generic:add-bank-account")}
      </RideButtonDeprecated>
      <BankAccountsList companies={[company]} onEditBankAccount={onEditBankAccount} />
    </div>
  );
};

export const AdminServiceViewSwitcher = ({
  gmbh,
  storageConfig,
  createAdminServiceAddOn,
  cancelAdminService,
  refetchCompany,
  formerClientAssignments,
  t
}: {
  gmbh: GmbHDetails;
  storageConfig: StorageConfigStructure[];
  createAdminServiceAddOn: () => Promise<ExecutionResult<CreateAdminServiceAddOn>>;
  cancelAdminService: (id: string) => Promise<void>;
  refetchCompany: () => Promise<void>;
  formerClientAssignments: GetFormerClientAssignments_getFormerClientAssignments_items[];
  t: TFunction;
}) => {
  const queryParams = useQueryParams();
  const showOnboardingCompleted = queryParams.get("onboarding-completed");

  const history = useHistory();
  const handleClick = async () => {
    const adminServiceId = (await createAdminServiceAddOn())?.data?.createAdminServiceAddOn?.id;
    if (adminServiceId) {
      history.push(`/admin-service/${adminServiceId}`);
    }
  };

  const adminServiceOnboardingInApp = FeatureFlagService.instance.isEnabled(
    FeatureFlags.AdminServiceInAppOnboarding
  );

  const isLegacyAS = !!gmbh.adminService?.orderedAt && !gmbh.adminService.tier;
  const canShowOnboarding =
    adminServiceOnboardingInApp && !isLegacyAS && formerClientAssignments.length === 0;

  const shouldShowCompanyRestrictionsToAdminService = FeatureFlagService.instance.isEnabled(
    FeatureFlags.CompanyCategoryQuestionShortOrderForm
  );

  const ufoOrder = gmbh.orders.find((o) => o.productType === "UnifiedShortOrderForm");
  const disabledAdminServiceTiers = getBlockedTiersForAdminService(
    {
      orderingBroker: ufoOrder?.extra.lowTaxBroker,
      companyOwnsRealEstate: ufoOrder?.extra.companyOwnsRealEstate,
      companyDoesNotFallIntoCategories: ufoOrder?.extra.companyDoesNotFallIntoCategories,
      tradesCrypto: ufoOrder?.extra.tradesCrypto,
      hasFiveOrMoreEmployees: ufoOrder?.extra.hasFiveOrMoreEmployees,
      companyIssuesMoreThan50OutgoingInvoicesYearly:
        ufoOrder?.extra.companyIssuesMoreThan50OutgoingInvoicesYearly,
      companyKeepsPhysicalInventory: ufoOrder?.extra.companyKeepsPhysicalInventory
    },
    shouldShowCompanyRestrictionsToAdminService
  );

  const cannotOrderAdminService = disabledAdminServiceTiers.disabledTiers.length === 3;

  const hasASOrderInProgress = gmbh.orders.some(
    (o) => o.productType === "AdminService" && (o.status === "InProgress" || o.status === "Booked")
  );

  const showAdminService =
    gmbh.adminServiceStatus === AdminServiceStatusEnum.ACTIVE ||
    (gmbh.adminServiceStatus === AdminServiceStatusEnum.WAITING_FOR_CONFIRMATION &&
      !hasASOrderInProgress) ||
    (gmbh.adminServiceStatus === AdminServiceStatusEnum.ORDER_PENDING && !hasASOrderInProgress);

  const currentTimestamp = moment.now();

  const showUpgradeASBanner =
    FeatureFlagService.instance.isEnabled(FeatureFlags.GRO777MigrateAdminServicePackage) &&
    gmbh.asUpgradeRequiredFrom &&
    new Date(gmbh.asUpgradeRequiredFrom).getTime() < currentTimestamp &&
    gmbh.customerSignedAdminServiceContract !== true;

  const isAsAvailableForCompany = !gmbh.isWepaClient;

  if (
    FeatureFlagService.instance.isEnabled(FeatureFlags.WealthNavigation) &&
    !isAsAvailableForCompany
  ) {
    return (
      <ProductPlaceholderPage
        titleTag="generic:product-unavailable-placeholder.admin-service.title"
        descriptionTag={[
          "generic:product-unavailable-placeholder.admin-service.description",
          "generic:product-unavailable-placeholder.admin-service.description-2"
        ]}>
        <RideButton
          className="mt-3"
          label={t("generic:schedule-consultation")}
          variant={RideButtonVariant.PRIMARY}
          size={RideButtonSize.BIG}
          onClick={() =>
            window.open(
              "https://meetings.hubspot.com/lars-weber2/old-customer-conversion-to-ride-steuerberatung",
              "_blank"
            )
          }
        />
      </ProductPlaceholderPage>
    );
  }

  return cannotOrderAdminService ? (
    <div className="company-restrictions-to-admin-service">
      <RideAlertBar
        data-testid={"company-restrictions-to-admin-service"}
        type={AlertType.INFO}
        message={t("generic:company-restrictions-to-admin-service")}
      />
    </div>
  ) : showOnboardingCompleted ? (
    <ThankYouPage tier={gmbh.adminService?.tier ?? undefined} continueUrl={gmbh.adminServiceUrl} />
  ) : (
    <>
      {showUpgradeASBanner && (
        <Container>
          <UpgradeAdminServiceToSelectTierBanner companyId={gmbh.id} />
        </Container>
      )}
      {showAdminService ? (
        <AdminService
          gmbh={gmbh}
          storageConfig={storageConfig}
          cancelAdminService={cancelAdminService}
        />
      ) : canShowOnboarding ? (
        <AdminServiceTiersOnboardingContainer gmbh={gmbh} refetchCompany={refetchCompany} />
      ) : (
        <PurchaseAdminService adminServiceAddOnRequest={{}} ctaAction={handleClick} />
      )}
    </>
  );
};

const AdminService = ({
  gmbh,
  storageConfig,
  cancelAdminService
}: {
  gmbh: GmbHDetails;
  storageConfig: StorageConfigStructure[];
  cancelAdminService: (id: string) => Promise<void>;
}) => {
  return (
    <div data-testid={"admin-service-page"}>
      {gmbh.adminServiceStatus === AdminServiceStatusEnum.ORDER_PENDING && (
        <Container>
          <ContinueOrderBanner
            dataTestId={"continue-admin-service-banner"}
            message={
              <Trans
                i18nKey={"generic:continue-admin-service-banner-message"}
                components={{ bold: <strong /> }}
              />
            }
            //TODO - test navigation
            ctaLink={`/admin-service/${gmbh.adminService?.id ?? ""}`}
            onClickCancel={() => cancelAdminService(gmbh.adminService?.id ?? "")}
          />
        </Container>
      )}
      <AdminServiceSignUpSection gmbh={gmbh} />
      <AdminServiceSection gmbh={gmbh} />
      <FeatureFlag name={FeatureFlags.FiscalYearDataUploadConfirmation}>
        {gmbh.fiscalYear && (
          <Container className="my-5">
            <FiscalYearDataUploadConfirmationContainer company={gmbh} role={UserRole.Client} />
          </Container>
        )}
      </FeatureFlag>
      {gmbh.hasAdminServiceStorage && storageConfig.length > 0 && (
        <Container>
          <DocumentSection
            testid="admin-service"
            gmbh={gmbh}
            nodeList={gmbh.adminServiceNodeList}
            storageConfig={storageConfig}
          />
        </Container>
      )}
    </div>
  );
};

import { RideSilverIcon } from "sharedComponents/icons/RideSilverIcon";
import React, { PropsWithChildren } from "react";
import { Container, Image, Nav } from "react-bootstrap";
import Language from "common/i18n/Language";
import i18n from "common/i18n";
import { TFunction, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import { FooterConfig } from "./FooterConfig";
import { DisabledFeatureFlag } from "../FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";
import { FeatureFlags } from "global-query-types";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";

export enum AppsVariant {
  Client = "Client",
  ThirdPartyTaxAdvisor = "ThirdPartyTaxAdvisor",
  PartnerTaxAdvisor = "PartnerTaxAdvisor"
}

export type FooterUrl = {
  url: string;
  urlTranslationKey: string;
};

export interface FooterProps extends PropsWithChildren<{}>, WithTranslation {
  footerUrls?: FooterUrl[];
  showLanguages?: boolean;
  app?: AppsVariant;
}

const RideFooter = ({
  t,
  app,
  footerUrls = FooterConfig.footerUrls,
  showLanguages = FooterConfig.showLanguages
}: FooterProps) => (
  <footer className="ride-footer" data-testid="ride-footer">
    <Container className="ride-footer__container">
      <FeatureFlag name={FeatureFlags.hasLanguageSwitcherInAllFooters}>
        <LanguageSwitcher />
      </FeatureFlag>
      <DisabledFeatureFlag name={FeatureFlags.hasLanguageSwitcherInAllFooters}>
        {showLanguages ? <LanguageSwitcher /> : <div />}
      </DisabledFeatureFlag>
      <UrlsList footerUrls={footerUrls} t={t} />
      <Copyright t={t} />
      <div className="ride-footer__icon ride-footer__section" data-testid="ride-footer-icon">
        <RideSilverIcon />
      </div>
    </Container>
  </footer>
);

const LanguageSwitcher = () => (
  <div className="ride-footer__language ride-footer__section" data-testid="ride-footer-language">
    <Nav.Link href="#" onClick={() => changeLanguage("en")}>
      <Image
        src="/assets/UK-flag.png"
        className="ride-footer__img"
        data-testid="ride-footer-uk-flag"
      />
      English
    </Nav.Link>
    <Nav.Link href="#" onClick={() => changeLanguage("de")}>
      <Image
        src="/assets/German-flag.png"
        className="ride-footer__img"
        data-testid="ride-footer-german-flag"
      />
      Deutsch
    </Nav.Link>
  </div>
);

const UrlsList = ({ footerUrls, t }: { footerUrls: FooterUrl[]; t: TFunction }) => (
  <div className="ride-footer__urls ride-footer__section" data-testid="ride-footer-urls">
    {footerUrls.map((value, index) => {
      let urlDescription = t(value.urlTranslationKey);
      return mountFooterURL(footerUrls.length, index, value.url, urlDescription);
    })}
  </div>
);

const Copyright = ({ t }: { t: TFunction }) => (
  <div className="ride-footer__copyright ride-footer__section" data-testid="ride-footer-copyright">
    {t("footer:copyright")}
  </div>
);

function mountFooterURL(
  arrayLength,
  index: number,
  url: string,
  urlDescription: string
): JSX.Element {
  return (
    <div key={index} className="ride-footer__link-item">
      <TextLink variant={LinkVariant.secondary} to={url} data-testid="ride-footer-url">
        {urlDescription}
      </TextLink>
      {arrayLength > 1 && !isTheItemInLastIndex(arrayLength, index) ? " | " : ""}
    </div>
  );
}

function isTheItemInLastIndex(arrayLength: any, index: number) {
  return arrayLength === index + 1;
}

function changeLanguage(languageCode) {
  const language = new Language(i18n);
  language.updateLanguage(languageCode);
}

export default withTranslationReady(["footer"])(RideFooter);

import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import ChoiceForm from "../../../../sharedComponents/ChoiceForm/choiceForm";
import { useLocation } from "react-router-dom";

interface AddGmbHOptionsProps extends WithTranslation {
  onAddNew: () => void;
  onAddExisting: () => void;
}

const AddGmbHOptions = ({ onAddNew, onAddExisting, t }: AddGmbHOptionsProps) => {
  const { pathname } = useLocation();
  return (
    <ChoiceForm
      lightMode={!pathname.includes("client/my-gmbhs/add")}
      title={t("client:add-gmbh.title")}
      firstSection={{
        title: t("client:add-gmbh.already-exists-gmbh"),
        btnText: t("client:add-gmbh.existing-gmbh-btn"),
        testId: "add-existing-gmbh"
      }}
      /*secondSection={{
        title: t("client:add-gmbh.new-gmbh"),
        btnText: t("client:add-gmbh.new-gmbh-btn"),
        testId: "add-new-gmbh"
      }} */
      onSelectFirst={onAddExisting}
      onSelectSecond={onAddNew}
    />
  );
};

export default withTranslationReady(["client"])(AddGmbHOptions);

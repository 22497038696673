import { useEffect } from "react";
import { logout } from "../../common/Authentication/logout";
import logger from "../../common/Logger";
import { Auth0Service } from "auth/services/auth0.service";

export const Logout = () => {
  useEffect(() => {
    handleLogout();
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = Auth0Service.getAuth0LogoutUrl();
    } catch (e) {
      logger.debug("failed logout");
    }
  };

  return null;
};

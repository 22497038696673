import React, { useState } from "react";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { WithTranslation } from "react-i18next";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { leiNumberValidation } from "lib/validation/CompanyLEINumberSchema";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";
import { LinkVariant, TextLink } from "../../../../../../../sharedComponents/TextLink/TextLink";
import { CardContainer } from "../../../../../../../uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { Typography } from "../../../../../../../uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideCheckbox,
  RideCheckboxVariant
} from "../../../../../../../uiLibrary/components/RideCheckbox/RideCheckbox";

const localModule = "company-lei-number";

const CompanyLEINumberStep = ({
  t,
  order,
  controls,
  saveData,
  currentStep
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const canShowLeiUpsell = FeatureFlagService.instance.isEnabled(
    FeatureFlags.GRO1377LeiBrokerOrderUpsell
  );

  const initialValues = {
    companyLEINumber: order?.extra?.companyLEINumber ?? "",
    leiPurchaseConsent: order?.extra?.leiPurchaseConsent ?? false
  };

  const onSubmit = async () => {
    currentStep.complete();
    const companyLEINumber = formik.values["companyLEINumber"];
    const leiPurchaseConsent = formik.values["leiPurchaseConsent"];

    await saveData(
      {
        ...order.extra,
        companyLEINumber: leiPurchaseConsent ? "" : companyLEINumber,
        leiPurchaseConsent,
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validateOnBlur: true,
    validate: (values) => {
      console.log(
        "validating: ",
        leiScreen,
        !values.leiPurchaseConsent,
        !leiNumberValidation(values.companyLEINumber)
      );

      if (leiScreen === "LEIUpsell" && !values.leiPurchaseConsent) {
        return { leiPurchaseConsent: t("generic:validation-required") };
      } else if (leiScreen === "LEIInput" && !leiNumberValidation(values.companyLEINumber)) {
        return { companyLEINumber: t("generic:invalid-pattern-of-lei-number") };
      } else {
        console.log("validation success");
      }
    }
  });

  const [leiScreen, setLeiScreen] = useState<"LEIInput" | "LEIUpsell">("LEIInput");

  const changeLeiForm = (leiScreen: "LEIInput" | "LEIUpsell") => {
    setLeiScreen(leiScreen);
    if (leiScreen === "LEIUpsell") {
      formik.setFieldValue("companyLEINumber", "");
    } else {
      formik.setFieldValue("leiPurchaseConsent", false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <CompanyLEINumber
          canShowLeiUpsell={canShowLeiUpsell}
          setLeiScreen={changeLeiForm}
          leiScreen={leiScreen}
        />
        {controls}
      </Form>
    </FormikProvider>
  );
};

const CompanyLeiNumberUpsellCard = withTranslationReady(["generic"])(
  ({
    t,
    setShowUpsellCard,
    canShowLeiUpsell
  }: {
    setShowUpsellCard: () => void;
    canShowLeiUpsell: boolean;
  } & WithTranslation) => {
    return (
      <>
        <CardContainer className={`${localModule}__card`} data-testid={`${localModule}-card`}>
          <div
            className={`${localModule}__text-group`}
            data-testid={`${localModule}-card-text-group`}>
            <Typography
              category={"Paragraph"}
              size={100}
              weight={"Heavy"}
              className={`${localModule}__title`}>
              {t("generic:ride-broker-onboarding.company-lei-number.upsell.title")}
            </Typography>
            <Typography
              category={"Paragraph"}
              size={100}
              weight={"Light"}
              className={`${localModule}__description`}>
              {t("generic:ride-broker-onboarding.company-lei-number.upsell.description")}
            </Typography>
          </div>
          <div className={`${localModule}__text-group`}>
            <Typography category={"Paragraph"} size={200} weight={"Light"}>
              {t("generic:ride-broker-onboarding.company-lei-number.upsell.CTA1")}
            </Typography>
          </div>
          <RideCheckbox
            data-testid={`${localModule}-checkbox`}
            variant={RideCheckboxVariant.Light}
            name="leiPurchaseConsent"
            label={t("generic:ride-broker-onboarding.company-lei-number.upsell.checkbox")}
            className={`${localModule}__checkbox`}
          />
        </CardContainer>
        {canShowLeiUpsell && (
          <TextLink
            role={"link"}
            variant={LinkVariant.primary}
            to={"#"}
            className={`${localModule}__upsell-link`}
            data-testid={`${localModule}-upsell-link-to-input`}
            onClick={() => setShowUpsellCard()}>
            {t("generic:ride-broker-onboarding.company-lei-number.upsell.link-to-input")}
          </TextLink>
        )}
      </>
    );
  }
);

const CompanyLEINumber = withTranslationReady(["generic"])(
  ({
    t,
    canShowLeiUpsell,
    leiScreen,
    setLeiScreen
  }: WithTranslation & {
    canShowLeiUpsell: boolean;
    leiScreen: "LEIInput" | "LEIUpsell";
    setLeiScreen: (leiScreen: "LEIInput" | "LEIUpsell") => void;
  }) => {
    const showUpsellCard = canShowLeiUpsell && leiScreen === "LEIUpsell";
    return (
      <div data-testid={localModule} className={`${localModule}`}>
        <StepHeading text={t("generic:ride-broker-onboarding.company-lei-number.title")} />
        {showUpsellCard ? (
          <div
            className={`${localModule}__upsell-container`}
            data-testid={`${localModule}-upsell-container`}>
            <CompanyLeiNumberUpsellCard
              setShowUpsellCard={() => setLeiScreen("LEIInput")}
              canShowLeiUpsell={canShowLeiUpsell}
            />
          </div>
        ) : (
          <RideTextInput
            name="companyLEINumber"
            label={t("generic:ride-broker-onboarding.company-lei-number.label")}
            placeholder={t("generic:ride-broker-onboarding.company-lei-number.placeholder")}
          />
        )}
        {leiScreen === "LEIInput" && canShowLeiUpsell && (
          <TextLink
            role={"link"}
            variant={LinkVariant.primary}
            to={"#"}
            className={`${localModule}__upsell-link`}
            data-testid={`${localModule}-upsell-link-to-card`}
            onClick={() => setLeiScreen("LEIUpsell")}>
            {t("generic:ride-broker-onboarding.company-lei-number.upsell.link-to-card")}
          </TextLink>
        )}
      </div>
    );
  }
);
export default withTranslationReady(["generic"])(CompanyLEINumberStep);

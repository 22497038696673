import { GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany } from "lib/api/administration/queries/graphql/GetEndOfYearTasksByCompany";
import { GetWepaFileImportById } from "lib/api/queries/GetWepaFileImportById";
import { GetWepaPortfolioWithDataAvailability } from "lib/api/queries/GetWepaPortfolioWithDataAvailability";
import { useCallback, useEffect } from "react";

export const usePortfolioDataCompleteness = (
  task: GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany
) => {
  const importId = task.extra?.uploadId as string | undefined;

  const { portfolio, portfolioError, loadingPortfolio, loadedPortfolio, refetchPortfolio } =
    useWepaPortfolio(task);
  const { fileImport, fileImportError, loadingFileImport, loadedFileImport, refetchFileImport } =
    useWepaFileImport(task, importId);

  const refetch = useCallback(() => {
    refetchPortfolio();
    refetchFileImport();
  }, [refetchPortfolio, refetchFileImport]);

  const importStatus = fileImport?.getWepaFileImportById?.status;
  useEffect(
    function fileImportPolling() {
      const refreshIntervalByStatus = {
        PENDING_IMPORT: 60 * 1000,
        IMPORT_IN_PROGRESS: 10 * 1000,
        JOB_STALLED: 60 * 1000
      };
      const status = fileImport?.getWepaFileImportById?.status || "";
      const refreshInterval = refreshIntervalByStatus[status];

      if (refreshInterval) {
        const interval = setInterval(refetch, refreshInterval);
        return () => clearInterval(interval);
      }
    },
    [importStatus, fileImport, refetch]
  );

  const isLoading =
    (loadingPortfolio && !loadedPortfolio) || (loadingFileImport && !loadedFileImport);

  return {
    portfolio: (portfolio && portfolio.getWepaPortfolio) || undefined,
    fileImport: (fileImport && fileImport.getWepaFileImportById) || undefined,
    isLoading,
    isLoaded: !!importId ? loadedPortfolio && loadedFileImport : loadedPortfolio,
    error: portfolioError || fileImportError,
    refetch
  };
};

const useWepaPortfolio = (task: GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany) => {
  const {
    data: portfolio,
    error: portfolioError,
    loading: loadingPortfolio,
    refetch
  } = GetWepaPortfolioWithDataAvailability({
    companyId: task.companyId,
    portfolioId: parseInt(task.referenceId!),
    fiscalYearStart: task.fiscalYearStart,
    fiscalYearEnd: task.fiscalYearEnd
  });

  const loadedPortfolio = !!portfolio || !!portfolioError;

  const refetchPortfolio = useCallback(
    () =>
      refetch({
        companyId: task.companyId,
        portfolioId: parseInt(task.referenceId!),
        fiscalYearStart: task.fiscalYearStart,
        fiscalYearEnd: task.fiscalYearEnd
      }),
    [task.companyId, task.referenceId, task.fiscalYearStart, task.fiscalYearEnd, refetch]
  );

  return { portfolio, portfolioError, loadingPortfolio, loadedPortfolio, refetchPortfolio };
};

const useWepaFileImport = (
  task: GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany,
  importId: string | undefined
) => {
  const {
    data: fileImport,
    error: fileImportError,
    loading: loadingFileImport,
    refetch
  } = GetWepaFileImportById({
    companyId: task.companyId,
    fileImportId: importId!,
    options: {
      skip: !importId
    }
  });

  const loadedFileImport = !!fileImport || !!fileImportError;

  const refetchFileImport = useCallback(
    () =>
      refetch({
        companyId: task.companyId,
        fileImportId: parseInt(importId!)
      }),
    [task.companyId, importId, refetch]
  );

  return {
    fileImport,
    fileImportError,
    loadingFileImport,
    loadedFileImport,
    refetchFileImport
  };
};

import React from "react";
import LoginForm from "./LoginForm";
import { useApolloClient, useMutation } from "react-apollo";
import { LOGIN } from "lib/api/mutations/LOGIN";
import { Login, LoginVariables } from "lib/api/mutations/graphql/Login";
import { SendSmsForTwoFactorAuth } from "lib/api/mutations/graphql/SendSmsForTwoFactorAuth";
import { SEND_SMS_FOR_TWO_FACTOR_AUTH } from "lib/api/mutations/SEND_SMS_FOR_TWO_FACTOR_AUTH";
import localStore from "../../../common/LocalStore";
import LoginEmailInputForm from "./LoginEmailInputForm";
import { USER_PASSWORD_STRATEGY } from "lib/api/queries/userPasswordStrategy";
import {
  userPasswordStrategy,
  userPasswordStrategyVariables
} from "lib/api/queries/graphql/userPasswordStrategy";
import { PwdStrategy } from "global-query-types";
import { Auth0Service } from "auth/services/auth0.service";

const useCheckLoginStrategy = () => {
  const apolloClient = useApolloClient();
  const [email, setEmail] = React.useState("");
  const [loginStrategy, setLoginStrategy] = React.useState<PwdStrategy | null>(null);
  const [isCheckingStrategy, setIsCheckingStrategy] = React.useState(false);

  async function checkLoginStrategy(email: string) {
    setEmail(email);
    setIsCheckingStrategy(false);

    try {
      const { data, errors } = await apolloClient.query<
        userPasswordStrategy,
        userPasswordStrategyVariables
      >({
        query: USER_PASSWORD_STRATEGY,
        variables: {
          email
        }
      });

      const passwordStrategy = data?.userPasswordStrategy;

      if (errors) {
        setLoginStrategy(PwdStrategy.RideLogin);
      } else if (passwordStrategy === PwdStrategy.Auth0) {
        window.location.href = Auth0Service.getAuth0LoginUrl(email);
        return;
      } else {
        setLoginStrategy(passwordStrategy);
      }
    } catch (error) {
      setLoginStrategy(PwdStrategy.RideLogin);
      console.error(error);
    } finally {
      setIsCheckingStrategy(false);
    }
  }

  function reset() {
    setLoginStrategy(null);
    setIsCheckingStrategy(false);
  }

  return {
    email,
    loginStrategy,
    checkLoginStrategy,
    isCheckingStrategy,
    reset
  };
};

export const LoginFormContainer = ({ redirectUrl }: { redirectUrl?: string }) => {
  const { email, checkLoginStrategy, isCheckingStrategy, loginStrategy, reset } =
    useCheckLoginStrategy();
  const [loginMutation, loginRequest] = useMutation<Login, LoginVariables>(LOGIN);

  const [sendSmsForTwoFactorAuthMutation] = useMutation<SendSmsForTwoFactorAuth>(
    SEND_SMS_FOR_TWO_FACTOR_AUTH
  );

  const handleLogin = async (email: string, password: string) => {
    const result = await loginMutation({
      variables: {
        email,
        password
      }
    });

    return result.data?.login;
  };

  const handleSendSmsForTwoFactorAuth = async () => {
    const result = await sendSmsForTwoFactorAuthMutation();

    if (result.data?.sendSmsForTwoFactorAuth?.nextSmsAvailableAt) {
      localStore.setExpiringValue(
        "nextSmsAvailableAt",
        result.data.sendSmsForTwoFactorAuth.nextSmsAvailableAt,
        60
      );
    }
  };

  if (!loginStrategy) {
    return (
      <LoginEmailInputForm onSubmit={checkLoginStrategy} isCheckingStrategy={isCheckingStrategy} />
    );
  } else {
    return (
      <LoginForm
        initEmail={email}
        onSubmit={handleLogin}
        onEditEmail={reset}
        requests={[loginRequest]}
        sendVerificationCode={handleSendSmsForTwoFactorAuth}
        redirectUrl={redirectUrl}
      />
    );
  }
};

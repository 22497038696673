import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetWepaV2Company, GetWepaV2CompanyVariables } from "./graphql/GetWepaV2Company";

export const GET_WEPA_V2_COMPANY_QUERY = gql`
  query GetWepaV2Company($companyId: ID!) {
    getWepaV2Company(id: $companyId) {
      id
      name
      shortTermEnabled
      isWhitelisted
      accountingFramework
      mandate {
        datevNumber
        datevSubnumber
      }
      taxAdvisor {
        datevNumber
        datevSubnumber
      }
      fiscalYear {
        day
        month
      }
    }
  }
`;

export const GetWepaV2CompanyQuery = (companyId: string) =>
  useQuery<GetWepaV2Company, GetWepaV2CompanyVariables>(GET_WEPA_V2_COMPANY_QUERY, {
    variables: { companyId }
  });

import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { AddIcon } from "../icons/add";
import React from "react";

interface SectionProps {
  title: string;
  btnText: string;
  btnVariant?: ButtonVariant;
  testId: string;
}

interface ChoiceFormProps {
  title: string;
  firstSection: SectionProps;
  secondSection?: SectionProps;
  onSelectFirst: () => void;
  onSelectSecond: () => void;
  lightMode?: boolean;
}

const ChoiceForm = ({
  title,
  firstSection,
  onSelectFirst,
  secondSection,
  onSelectSecond,
  lightMode = false
}: ChoiceFormProps) => (
  <div className="choice-form">
    <div
      className={`choice-form__title${lightMode ? "--light" : ""}`}
      data-testid="choice-form-title">
      {title}
    </div>

    <Section
      title={firstSection.title}
      btnText={firstSection.btnText}
      btnVariant={firstSection.btnVariant ?? ButtonVariant.Primary}
      testid={firstSection.testId}
      onClick={onSelectFirst}
      lightMode={lightMode}
    />

    {secondSection && (
      <Section
        title={secondSection.title}
        btnText={secondSection.btnText}
        btnVariant={secondSection.btnVariant ?? ButtonVariant.Primary}
        testid={secondSection.testId}
        onClick={onSelectSecond}
        lightMode={lightMode}
      />
    )}
  </div>
);

const Section = ({
  title,
  btnText,
  btnVariant,
  testid,
  onClick,
  lightMode
}: {
  title: string;
  btnText: string;
  btnVariant: ButtonVariant;
  testid: string;
  onClick: () => void;
  lightMode: boolean;
}) => (
  <div className="section">
    <div className={`section__title${lightMode ? "--light" : ""}`} data-testid={`${testid}-title`}>
      {title}
    </div>

    <div className="d-flex justify-content-center mt-2">
      <RideButtonDeprecated
        data-testid={`${testid}-btn`}
        hasIcon={true}
        darkMode={true}
        size={ButtonSize.Big}
        variant={btnVariant}
        onClick={onClick}>
        <AddIcon />
        {btnText}
      </RideButtonDeprecated>
    </div>
  </div>
);

export default ChoiceForm;
